<template>
  <ion-page id="page-content">
    <ion-modal @didDismiss="showStore = false" :is-open="showStore" :breakpoints="[0, 0.6, 1]"
      :initialBreakpoint="$store.state.isPC ? 1 : 0.6" css-class="home-store-modal">
      <ion-content class="ion-padding">
        <storeListModel @selectEvent="selectEvent" @hidemodel="hidemodel" :selectId="selectStore.storeId">
        </storeListModel>
      </ion-content>
    </ion-modal>
    <ion-modal @didDismiss="showDelDialog = false" :is-open="showDelDialog" css-class="tcc-modal">
      <div class="model-box">
        <div class="ion-page">
          <div class="modal-content friend-modal">
            <p class="font-18 font-b main-color">Delete your Account?</p>
            <p class="font-16 gery-color" style="margin-top: 30px">
              Please note this action will delete your Ritchies account permanently. Are
              you sure you want to proceed?
            </p>
            <div class="btn-box" style="
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 30px;
              ">
              <button style="border: 1px solid #223b86; color: #223b86" class="btn" @click="showDelDialog = false">
                Cancel
              </button>
              <button style="background-color: #223b86; color: #ffffff" class="btn" @click="delEvent">
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </ion-modal>
    <ion-modal @didDismiss="showChange = false" :is-open="showChange" css-class="tcc-modal">
      <div class="model-box">
        <div class="ion-page">
          <div class="modal-content friend-modal">
            <p class="font-18 font-b main-color">
              Do you really want to leave? you have unsaved changes!
            </p>
            <div class="btn-box" style="
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 30px;
              ">
              <button style="border: 1px solid #223b86; color: #223b86" class="btn" @click="showChange = false">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </ion-modal>
    <ion-content :fullscreen="true">
      <topbar :bigtitle="true" title="Edit Profile" :class="{ 'letter-ios-05': $store.state.deviceType == 1 }"
        :showBack="true" :backevent="backEvent"></topbar>
      <div class="pc-box big">
        <div class="pc-min-content">
          <div class="content">
            <div class="form-item center">
              <div class="userIcon pointer" @click="readyTake">
                <img class="icon" :src="$store.state.form.userInfo.icon
                  ? $store.state.form.userInfo.icon
                  : defaultIcon
                  " />
                <img class="edit" :src="edit" />
              </div>
            </div>
            <!-- <div class="form-item">
          <label>Username</label>
          <div class="input-box">
            <input
              type="text"
              disabled
              v-model="$store.state.form.userInfo.userName"
            />
          </div>
        </div> -->
            <div class="form-item">
              <label>First Name</label>
              <div class="input-box">
                <input type="text" v-model="$store.state.form.userInfo.firstName" maxlength="64"
                  placeholder="First Name" />
              </div>
            </div>
            <div class="form-item">
              <label>Last Name</label>
              <div class="input-box">
                <input type="text" v-model="$store.state.form.userInfo.lastName" maxlength="64" placeholder="Last Name" />
              </div>
            </div>
            <div class="form-item" v-if="$store.state.form.userInfo.userType == 1">
              <label :class="{
                required:
                  $store.state.form.userInfo.isMarketingInfo ||
                  $store.state.form.userInfo.isPaperReceipt,
              }">Email</label>
              <div class="input-box">
                <input type="text" :style="!$store.state.form.userInfo.isEmailValidation
                  ? 'width: calc(100% - 80px);padding:0 10px 0 20px'
                  : 'width: 100%'
                  " class="whitedisabled nocssdis" disabled @input="
    $store.state.form.userInfo.email = $store.state.form.userInfo.email
      .trim()
      .toLowerCase()
    " v-model="$store.state.form.userInfo.email" placeholder="Email Address" />
                <i class="icon-arrowRight" @click="goBindEvent(1)"></i>
                <img class="tick" :src="tick" v-if="$store.state.form.userInfo.isEmailValidation" />
                <span v-else class="Medium" @click="verifyEvent(1)" style="color: #00a1de; cursor: pointer">Verify</span>
              </div>
            </div>
            <div class="form-item" v-else>
              <label :class="{
                required:
                  $store.state.form.userInfo.isMarketingInfo ||
                  $store.state.form.userInfo.isPaperReceipt,
              }">Email</label>
              <div class="input-box">
                <ion-input type="text" :style="!$store.state.form.userInfo.isEmailValidation
                  ? 'width: calc(100% - 80px);padding:0 10px 0 20px'
                  : 'width: 100%'
                  " @ionBlur="checkFormat('email')" @ionInput="
    $store.state.form.userInfo.email = $event.target.value
      .trim()
      .toLowerCase();
  errorFormatEmail = false;
  nullEmail = false;
  " class="whitedisabled" v-model.trim="$store.state.form.userInfo.email" placeholder="Email Address" />
                <!-- <i class="icon-arrowRight" @click="goBindEvent(1)"></i>
                <img
                  class="tick"
                  :src="tick"
                  v-if="$store.state.form.userInfo.isEmailValidation"
                />
                <span v-else class="Medium" @click="verifyEvent(1)" style="color: #00a1de; cursor: pointer;">Verify</span> -->
              </div>
              <p v-if="errorFormatEmail" class="tip red">
                Email format is incorrect
              </p>
              <p v-if="nullEmail" class="tip red">
                Email format is incorrect
              </p>
            </div>
            <div class="form-item" v-if="$store.state.form.userInfo.userType == 2">
              <label>Mobile number</label>
              <div class="mobile-number item-table">
                <!-- <div class="tdcell code">
                  <span>+61</span><i class="icon-arrowLeft-2"></i>
                </div> -->
                <div class="tdcell phone">
                  <input class="whitedisabled nocssdis" disabled :style="!$store.state.form.userInfo.isPhoneValidation
                    ? 'width: calc(100% - 80px);padding:0 10px 0 20px'
                    : 'width: 100%;padding:0 10px 0 20px'
                    " v-model="$store.state.form.userInfo.phone" type="tel" maxlength="10"
                    placeholder="Mobile number" />
                  <i class="icon-arrowRight" @click="goBindEvent(2)"></i>
                  <img class="tick" :src="tick" v-if="$store.state.form.userInfo.isPhoneValidation" />
                  <span v-else class="Medium" @click="verifyEvent(2)"
                    style="color: #00a1de; cursor: pointer">Verify</span>
                </div>
              </div>
            </div>
            <div class="form-item" v-else>
              <label>Mobile number</label>
              <div class="mobile-number item-table">
                <!-- <div class="tdcell code">
                  <span>+61</span><i class="icon-arrowLeft-2"></i>
                </div> -->
                <div class="tdcell phone">
                  <ion-input class="whitedisabled" :style="!$store.state.form.userInfo.isPhoneValidation
                    ? 'width: calc(100% - 80px);padding:0 10px 0 20px'
                    : 'width: 100%;padding:0 10px 0 20px'
                    " v-model="$store.state.form.userInfo.phone" @ionBlur="checkFormat('phone')"
                    @ionInput="errorFormatPhone = false" type="tel" maxlength="10" placeholder="Mobile number" />
                  <!-- <i class="icon-arrowRight" @click="goBindEvent(2)"></i>
                  <img
                    class="tick"
                    :src="tick"
                    v-if="$store.state.form.userInfo.isPhoneValidation"
                  />
                  <span v-else class="Medium" @click="verifyEvent(2)" style="color: #00a1de; cursor: pointer;">Verify</span> -->
                </div>
                <p v-if="errorFormatPhone" class="tip red">
                  Mobile format is incorrect
                </p>
              </div>
            </div>
            <!-- <div class="form-item">
          <label>Gender</label>
          <div class="input-box item-table Gender-box">
            <div
              class="tdcell Male Gender"
              @click="$store.state.form.userInfo.gender = 'Male'"
              :class="{ select: $store.state.form.userInfo.gender == 'Male' }"
            >
              Male
            </div>
            <div
              class="tdcell Female Gender"
              @click="$store.state.form.userInfo.gender = 'Female'"
              :class="{ select: $store.state.form.userInfo.gender == 'Female' }"
            >
              Female
            </div>
          </div>
        </div> -->
            <div class="form-item">
              <label>Home Store</label>
              <div class="input-box" @click="showStoreList">
                <input type="text" class="whitedisabled pointer nocssdis" disabled v-model="selectStore.name"
                  placeholder="Home Store" />
                <i class="icon-arrowRight pointer"></i>
              </div>
            </div>
            <!-- <hr class="bar" />
        <div class="form-item facebook">
          <h2>Social Media Accounts</h2>
          <div class="input-box">
            <div class="link-fb">Link with Facebook</div>
            <i class="icon-facebook"></i>
          </div>
        </div> -->
            <hr class="bar" />
            <div class="form-item media">
              <h2 class="Bold">
                Digital Card Number
                <!-- <p class="font-16 Medium" style="color: rgba(0, 0, 0, 0.6); font-weight: normal; margin-top: 5px">(enter card number to link your card)</p> -->
              </h2>
              <div class="input-box">
                <input type="text" class="nocssdis" v-model="$store.state.form.userInfo.qrCode" disabled
                  placeholder="Card Number" />
                <i class="icon-card pointer"></i>
              </div>
            </div>
            <hr class="bar" />
            <div class="form-item media">
              <h2 class="Bold">
                Existing Physical Card (Optional)
                <p class="font-16 Medium" style="color: #666666; font-weight: normal; margin-top: 5px">
                  (enter card number to link your card)
                </p>
              </h2>
              <div class="input-box flex-center-center" v-if="$store.state.appVersion">
                <input type="text" v-model="$store.state.form.userInfo.physicalCardNumber" @blur="checkCardNum"
                  @input="errorCardNum = false" class="whitedisabled pointer" placeholder="Card Number" />
                <i class="icon-card pointer"></i>
                <i class="icon-shape pointer" @click="goScanEvent"></i>
              </div>
              <div class="input-box flex-center-center" v-else>
                <input type="text" v-model="$store.state.form.userInfo.physicalCardNumber" @blur="checkCardNum"
                  @input="errorCardNum = false" placeholder="Card Number" />
                <i class="icon-card pointer"></i>
                <i class="icon-shape pointer" @click="goScanEvent"></i>
              </div>
              <p class="tip red" v-if="errorCardNum">
                Number format is incorrect
              </p>
            </div>
            <hr class="bar" />

            <div class="form-item" style="padding-top: 30px">
              <label>Date of Birth (Optional)</label>
              <van-icon id="trigger-button" size="24" name="question" style="margin-left: 10px; vertical-align: middle"
                color="#223b86" />
              <ion-popover :show-backdrop="false" trigger="trigger-button" mode="ios" :translucent="true">
                <ion-content :forceOverscroll="false">
                  <div class="tip-date">
                    <p>
                      Some offers are 18+ restricted (liquor). Please confirm your DOB.
                    </p>
                  </div>
                </ion-content>
              </ion-popover>
              <div class="date-input-container">
                <!-- <div class="input-box" style="width: 40px">
                  <input type="tel" maxlength="2" @blur="checkDate('DD')" class="p-l-5" v-model="birthdayDD" placeholder="DD" />
                </div> -->
                <!-- <span style="display: inline-block; margin: 0 10px">/</span> -->
                <div class="input-box" style="width: 50px">
                  <input type="tel" maxlength="2" style="text-align: center" @blur="checkDate('MM')" class="p-l-5"
                    v-model="birthdayMM" placeholder="MM" />
                </div>
                <span style="display: inline-block; margin: 0 15px">/</span>
                <div class="input-box" style="width: 70px">
                  <input type="tel" maxlength="4" style="text-align: center" @blur="checkDate('YY')" class="p-l-5"
                    v-model="birthdayYY" placeholder="YYYY" />
                </div>
              </div>
              <p class="tip red" v-if="errorFormatDate.join('') != ''">
                {{ errorFormatDate.join("") }} format is incorrect
              </p>
            </div>
            <hr class="bar" />
            <div class="item-table flex-center-center pad-t-30">
              <div class="tdcell checkbox f-s-0">
                <van-checkbox v-model="$store.state.form.userInfo.isPaperReceipt" shape="square"
                  checked-color="#00a1de"></van-checkbox>
              </div>
              <div class="tdcell Medium">
                Yes I want digital receipts.
                <br />
                <span>Digital receipts will be sent to the registered email address.</span>
              </div>
            </div>
            <div class="item-table flex-center-center" style="padding-top: 30px; padding-bottom: 0 !important">
              <div class="tdcell checkbox f-s-0">
                <van-checkbox shape="square" checked-color="#00a1de"
                  v-model="$store.state.form.userInfo.isMarketingInfo"></van-checkbox>
              </div>
              <div class="tdcell Medium">
                <p>I would like to receive online specials / promotions comms.</p>
              </div>
            </div>
            <div class="confirm-box">
              <ion-button style="margin-bottom: 20px" expand="block" @click="confirmEvent">Save</ion-button>
              <img @click="delAcc()" src="@/assets/button-outline@3x.png" />
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonContent,
  IonButton,
  IonModal,
  IonPopover,
  IonInput,
} from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import storeListModel from "@/components/storeListModel.vue";
import config from "../config";
import { gtag } from "@/ga.js";
// import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
// import Datepicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "editProfile",
  mixins: [config.globalMixin],
  components: {
    IonContent,
    IonPage,
    topbar,
    IonButton,
    IonModal,
    storeListModel,
    IonPopover,
    IonInput,
    // Datepicker,
  },
  computed: {
    birthday: function () {
      return (
        this.isPadZero(this.birthdayYY) +
        "/" +
        this.isPadZero(this.birthdayMM) +
        "/" +
        this.isPadZero(this.birthdayDD)
      );
    },
    userTemp: function () {
      return JSON.parse(JSON.stringify(this.$store.state.form.userInfo));
    },
  },
  watch: {
    userTemp: {
      handler(n, o) {
        if (n.firstName != o.firstName) {
          this.hasChange = true;
        }
        if (n.lastName != o.lastName) {
          this.hasChange = true;
        }
        if (n.email != o.email) {
          this.hasChange = true;
        }
        if (n.phone != o.phone) {
          this.hasChange = true;
        }
        if (n.storeName != o.storeName) {
          this.hasChange = true;
        }
        if (n.physicalCardNumber != o.physicalCardNumber) {
          this.hasChange = true;
        }
        if (n.birthday != o.birthday) {
          this.hasChange = true;
        }
        if (n.isPaperReceipt != o.isPaperReceipt) {
          this.hasChange = true;
        }
        if (n.isMarketingInfo != o.isMarketingInfo) {
          this.hasChange = true;
        }
      },
      deep: true,
    },
    selectStore: {
      handler(n, o) {
        if (n.name != o.name) {
          this.hasChange = true;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      gender: 1,
      showStore: false,
      storeId: "",
      selectStore: {},
      edit: require("@/assets/edit2.png"),
      tick: require("@/assets/tick.png"),
      birthdayDD: "01",
      birthdayMM: "",
      birthdayYY: "",
      errorCardNum: false,
      errorFormatPhone: false,
      errorFormatEmail: false,
      nullEmail: false,
      startDate: new Date("1900/01/01"),
      errorFormatDate: ["", "", ""],
      smallMM: [4, 6, 9, 11],
      showDelDialog: false,
      hasChange: false,
      showChange: false,
      canSendS: true,
      canSendE: true,
    };
  },
  methods: {
    backEvent() {
      this.getUserPofileEvent(function () { });

      this.$router.go(-1);
    },
    isPadZero(num) {
      if (num.length < 2) {
        return num.padStart(2, "0");
      } else {
        return num;
      }
    },
    readyTake() {
      if (this.hasChange) {
        this.showChange = true;
        return;
      }
      console.log(this.hasChange);
      this.takePicture();
    },
    checkDate(type) {
      if (type == "MM") {
        if (this.birthdayMM > 0 && this.birthdayMM <= 12) {
          this.errorFormatDate[1] = "";
          return true;
        } else if (this.birthdayMM == "" && this.birthdayYY != "") {
          this.errorFormatDate[1] = " MM ";
          return false;
        } else if (this.birthdayMM == "" && this.birthdayYY == "") {
          this.errorFormatDate[1] = "";
          this.errorFormatDate[2] = "";
          return true;
        } else {
          this.errorFormatDate[1] = " MM ";
          return false;
        }
      } else if (type == "YY") {
        let nowYear = new Date().getFullYear();
        if (this.birthdayYY >= 1900 && this.birthdayYY <= nowYear) {
          this.errorFormatDate[2] = "";
          return true;
        } else if (this.birthdayMM != "" && this.birthdayYY == "") {
          this.errorFormatDate[2] = " YY ";
          return false;
        } else if (this.birthdayMM == "" && this.birthdayYY == "") {
          this.errorFormatDate[1] = "";
          this.errorFormatDate[2] = "";
          return true;
        } else {
          this.errorFormatDate[2] = " YY";
          return false;
        }
      }
    },
    delAcc() {
      this.showDelDialog = true;
    },
    async confirmEvent() {
      const objdata = this.getAuthObj();
      // for (const key in this.$store.state.form.userInfo) {
      //   const detail = JSON.stringify(this.$store.state.form.userInfo[key]);
      //   objdata[key] = JSON.parse(detail);
      // }
      if (this.errorCardNum) {
        return;
      }
      if (
        (this.$store.state.form.userInfo.isMarketingInfo ||
          this.$store.state.form.userInfo.isPaperReceipt) &&
        this.$store.state.form.userInfo.email == ""
      ) {
        this.nullEmail = true;
        return;
      }
      objdata.email = this.$store.state.form.userInfo.email.trim().toLowerCase();
      objdata.phoneAreaCode = this.$store.state.form.userInfo.phoneAreaCode;
      objdata.phone = this.$store.state.form.userInfo.phone;
      objdata.gender = this.$store.state.form.userInfo.gender;
      objdata.isMarketingInfo = this.$store.state.form.userInfo.isMarketingInfo;
      objdata.emailVerifyCode = this.$store.state.form.userInfo.emailVerifyCode;
      objdata.smsVerifyCode = this.$store.state.form.userInfo.smsVerifyCode;
      objdata.physicalCardNumber = this.$store.state.form.userInfo.physicalCardNumber;
      objdata.firstName = this.$store.state.form.userInfo.firstName;
      objdata.lastName = this.$store.state.form.userInfo.lastName;
      objdata.snsType = this.$store.state.form.userInfo.snsType;
      objdata.snsId = this.$store.state.form.userInfo.snsId;
      objdata.snsAccessToken = this.$store.state.form.userInfo.snsAccessToken;
      objdata.isPaperReceipt = !this.$store.state.form.userInfo.isPaperReceipt;
      if (this.birthdayMM != "" || this.birthdayYY != "") {
        if (
          (this.birthdayYY % 4 == 0 && this.birthdayYY % 100 != 0) ||
          this.birthdayYY % 400 == 0
        ) {
          if (this.birthdayMM == 2 && this.birthdayDD > 29) {
            this.errorFormatDate[0] = "DD ";
            return;
          } else if (
            this.smallMM.indexOf(Number(this.birthdayMM)) > -1 &&
            this.birthdayDD > 30
          ) {
            this.errorFormatDate[0] = "DD ";
            return;
          }
        } else {
          if (this.birthdayMM == 2 && this.birthdayDD > 28) {
            this.errorFormatDate[0] = "DD ";
            return;
          } else if (
            this.smallMM.indexOf(Number(this.birthdayMM)) > -1 &&
            this.birthdayDD > 30
          ) {
            this.errorFormatDate[0] = "DD ";
            return;
          }
        }
        if (
          this.errorFormatDate.join("") == "" &&
          this.birthdayMM != "" &&
          this.birthdayYY != ""
        ) {
          objdata.birthday = this.formatDate(this.birthday, "YYYY-MM-DD") + " 00:00:00";
        } else {
          if (this.birthdayMM == "") {
            this.errorFormatDate[1] = "MM ";
          } else if (this.birthdayYY == "") {
            this.errorFormatDate[2] = "YY ";
          }
          return false;
        }
      }

      objdata.storeId = this.selectStore.storeId;
      objdata.icon = this.$store.state.form.userInfo.icon;
      objdata.apiUrl = this.apiConfig.updateUserProfile;
      const data = await this.$store.dispatch("apiPutEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      // succeeded set Birthday
      this.$store.state.form.userInfo.birthday =
        this.formatDate(this.birthday, "YYYY-MM-DD") + " 00:00:00";
      this.hasChange = false;
      this.sendGAEvent("Click", "Edit Profile", "Save");
      this.successToast("Success!");
      //   this.backEvent();
    },
    showStoreList() {
      this.showStore = true;
    },
    goScanEvent() {
      if (this.hasChange) {
        this.showChange = true;
        return;
      }
      this.$router.push({
        path: "/scanCode",
      });
      this.sendGAEvent("Click", "Edit Profile", "Scan Code");
    },
    hidemodel() {
      this.showStore = false;
    },
    async delEvent() {
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.sendDeleteAccountEmail;
      const data = await this.$store.dispatch("apiEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.showDelDialog = false;
      this.sendGAEvent("Click", "Edit profile", "Delete account");
      this.logoutEvent();
    },
    async logoutEvent() {
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.logout;
      await this.$store.dispatch("apiEvent", objdata);
      this.$store.state.form.token = "";
      this.$store.state.form.eCouponList = [];
      this.$store.state.form.stampList = [];
      this.$store.state.form.collectCardList = [];
      this.$store.state.userInfo = {};
      this.setStorageEvent();
      gtag("config", process.env.VUE_APP_GA4_ID, {
        "uid": "",
      });
      if (this.$store.state.appVersion) {
        this.$router.replace({
          path: "/main/landing",
        });
      } else {
        this.$router.replace({
          path: "/register",
        });
      }
    },
    selectEvent(store) {
      if (store) {
        this.selectStore = store;
      }
      this.showStore = false;
    },
    goBindEvent(type) {
      if (this.hasChange) {
        this.showChange = true;
        return;
      }
      if (type == 1) {
        this.$store.state.form.email = this.$store.state.form.userInfo.email
          .trim()
          .toLowerCase();
        this.sendGAEvent("Click", "Edit profile", "Set Email");
      } else {
        this.$store.state.form.phone = this.$store.state.form.userInfo.phone;
        this.sendGAEvent("Click", "Edit profile", "Set Mobile");
      }
      this.$router.push({
        path: "/changeMobileEmail/" + type,
      });
    },
    checkCardNum() {
      let numStr = String(this.$store.state.form.userInfo.physicalCardNumber);
      let numNum = this.$store.state.form.userInfo.physicalCardNumber;
      let numReg = /^\+?(0|[1-9]\d*)$/;
      if (numStr.length == 0 || numStr == "null") {
        this.errorCardNum = false;
        return;
      }
      if (!numReg.test(numNum)) {
        this.errorCardNum = true;
        return;
      }
      if (
        numStr.length != 13 ||
        (numStr.slice(0, 4) != "2000" && numStr.slice(0, 4) != "2001")
      ) {
        this.errorCardNum = true;
      } else {
        this.errorCardNum = false;
      }
    },
    verifyEvent(type) {
      this.$store.state.form.isConfirm = this.isConfirm;
      this.$store.state.form.profileVerifyType = "editProfile";
      if (type == 1) {
        this.$store.state.form.email = this.$store.state.form.userInfo.email
          .trim()
          .toLowerCase();
        this.sendEmailEvent();
      } else {
        this.$store.state.form.phone = this.$store.state.form.userInfo.phone;
        this.sendSmsEvent();
      }
    },
    async sendSmsEvent() {
      if (!this.canSendS) return;
      this.canSendS = false;
      const objdata = this.getAuthObj();
      objdata.phone = this.$store.state.form.userInfo.phone;
      objdata.phoneAreaCode = this.$store.state.userInfo.phoneAreaCode;
      objdata.apiUrl = this.apiConfig.sendSms;
      objdata.verifyType = 3;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendS = true;
      }, 1500);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      if (this.hasChange) {
        this.showChange = true;
        return;
      }
      this.$router.push({
        path: "/verifyCode/3/2",
      });
    },
    checkFormat(type) {
      let confirmType = type == "phone" ? "Phone" : "Email";
      if (
        this.$store.state.form.userInfo[type] == "" ||
        this.$store.state.form.userInfo[type] == undefined
      ) {
        return;
      }
      if (
        !this.$store.state[`${type}Patrn`].test(this.$store.state.form.userInfo[type])
      ) {
        this[`errorFormat${confirmType}`] = true;
      }
    },
    async sendEmailEvent() {
      if (!this.canSendE) return;
      this.canSendE = false;
      const objdata = this.getAuthObj();
      objdata.email = this.$store.state.form.userInfo.email.trim().toLowerCase();
      objdata.apiUrl = this.apiConfig.sendVerifyEmail;
      objdata.verifyType = 3;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendE = true;
      }, 1500);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      if (this.hasChange) {
        this.showChange = true;
        return;
      }
      this.$router.push({
        path: "/verifyCode/3/1",
      });
    },
    // changeBirthday(event) {
    //   alert(event.detail.value)
    //   let date = event.detail.value.split("T")[0]
    //   this.birthday = date + " 00:00:00";
    //   // this.$store.state.form.userInfo.birthday = this.formatDate(event.detail.value, "YYYY-MM-DD") + " 00:00:00"
    // }
  },
  ionViewWillLeave() {
    this.getUserPofileEvent(function () { });
  },
  ionViewDidEnter() {
    this.showStore = false;
    this.selectStore.storeId = this.$store.state.form.userInfo.storeId;
    this.selectStore.name = this.$store.state.form.userInfo.storeName;
    if (this.$store.state.form.userInfo.birthday) {
      this.birthdayDD = this.$store.state.form.userInfo.birthday
        .split(" ")[0]
        .split("-")[2];
      this.birthdayMM = this.$store.state.form.userInfo.birthday
        .split(" ")[0]
        .split("-")[1];
      this.birthdayYY = this.$store.state.form.userInfo.birthday
        .split(" ")[0]
        .split("-")[0];
    }
    this.$store.state.exitApp = false;
    this.sendGAPageView("Edit Profile");
  },
}

</script>
<style scoped>
ion-popover {
  --background: #3e3e3e;
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  --width: 300px;
}

ion-popover ion-content {
  --background: #3e3e3e;
}

ion-popover::part(arrow)::after {
  background-color: #3e3e3e;
}
</style>
